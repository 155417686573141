import React from 'react';
import Entry from "./enter";

const EntryDI = () => {

    const initialStateOverride = {
        showDraw: true,
        showFast: false,
        showInstant: true,
    }

    return (
        <Entry
            { ... { initialStateOverride } }
        />
    );
};


export default EntryDI;

